import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //vuex的基本数据，用来存储变量
  state: {
    // 登录 token
    token: "",
    iskeep:'1',
    // 保存的 路由地址
    holdroute:[{
      url:"/",
      name:"首页",
      type:""
    }]
  },
  //相当于state的计算属性
  getters: {},
  //vuex 的方法，必须是同步的(如果需要异步使用action)
  mutations: {
    // 登录
    login(state, token) {
      state.token = token;
      // 缓存token
      localStorage.setItem("xcx_pc_token",token);
    },

    // 退出
    logout(state) {
      state.token = "";
      // 清空对应的 key token
      localStorage.removeItem("xcx_pc_token")
    },
    //保存-路由
    baocuntabrout(state,holdroute){
      state.holdroute=holdroute;
    },
    //学校
    keepSchool(state,holdroute){
      state.iskeep=holdroute;
    },
     //还原默认 路由
    reduction(state){
      state.holdroute=[{
        url:"/",
        name:"首页",
         type:""
    }]
    },

  },
  actions: {},
  modules: {}

})
